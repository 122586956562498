class ComponentProductsFilter {

    constructor() {
        this.productsFilter = document.querySelector('.products-filter');

        if(this.productsFilter != null){
            this.initFilter();
        }
    }

    initFilter() {
        let productsFilter = this.productsFilter;

        let selectSortBy = document.querySelector('.js-selectSortBy');
        let selectSortByValue = selectSortBy.querySelector('.selectProducts-trigger');
        let selectSortByOptions = selectSortBy.querySelector('.selectProducts-options');

        let selectProducer = document.querySelector('.js-selectProducer');
        let selectProducerValue = selectProducer.querySelector('.selectProducts-trigger');
        let selectProducerOptions = selectProducer.querySelector('.selectProducts-options');

        //Watch for Ucinek
        Array.from(selectSortByOptions.children).forEach(function (elOption) {
            elOption.addEventListener("click", (e) => {
                let value = e.target.getAttribute("data-value");

                selectSortByValue.textContent = e.target.textContent;
                selectSortByValue.setAttribute("data-value", value);

                productsFilter.setAttribute("data-sort", value);
                //$(".products-filter").mydata('data-sort', value);

                // Close select
                selectSortBy.classList.remove("is-active");
            });
        });

        //Watch for Forma
        Array.from(selectProducerOptions.children).forEach(function (elFOption){
            elFOption.addEventListener("click", (e) => {
                let value = e.target.getAttribute("data-value");

                selectProducerValue.textContent = e.target.textContent;
                selectProducerValue.setAttribute("data-value", value);

                productsFilter.setAttribute("data-producers", value);
                //$(".products-filter").mydata('data-producers', value);

                // Close select
                selectProducer.classList.remove("is-active");
            });
        });

        //Toggle ucinek select
        selectSortByValue.addEventListener("click", (e) => {
            selectSortBy.classList.toggle("is-active");
            //observer.observe(productsFilter, options);
        });
        //Toggle forma select
        selectProducerValue.addEventListener("click", (e) => {
            selectProducer.classList.toggle("is-active");
            //observer.observe(productsFilter, options);
        });

        //Close selects when clicking outside
        document.addEventListener("click", (e) => {
            const didClickedOutsideSortBy = !selectSortBy.contains(event.target);
            if (didClickedOutsideSortBy) {
                selectSortBy.classList.remove("is-active");
            }

            const didClickedOutsideProducer = !selectProducer.contains(event.target);
            if (didClickedOutsideProducer) {
                selectProducer.classList.remove("is-active");
            }
        });

    }

} export default ComponentProductsFilter;
