import Constants from "../Constants";

class ComponentNavigation {

    constructor() {
        this.navigation = document.querySelector(".navigation--main");
        this.header = document.querySelector(".header");
        this.hamburger = document.querySelector(".navigation-hamburger");
        this.dropdownProducts = document.querySelector("#produktyDropdown");
        this.cartDropdown = document.querySelector("#cartDropdown");

        if(this.navigation){
            this.initHamburger();
        }

        //if(this.header){
        //    this.initStickyHeader();
        //}
    }

    initHamburger() {
        this.hamburger.addEventListener("click", e => {
            e.preventDefault();

            this.navigation.classList.toggle(Constants.classNavigationOpened);
            createOffCanvas();

            const getCanvas = document.querySelector('.nav-responsive');
            if(getCanvas){
                getCanvas.classList.toggle('nav-responsive--opened');

                let closeBtn = getCanvas.querySelector('.close-respo');

                //closeBTN menu DESTROY
                closeBtn.addEventListener("click", e => {
                    e.preventDefault();
                    destroyOffCanvas();
                });

                //click outside menu DESTROY
                document.addEventListener("click", function(event) {
                    if (!event.target.closest('.navigation') && !event.target.closest('.nav-responsive')){
                        //document.querySelector('.nav-responsive').classList.remove('nav-responsive');
                        destroyOffCanvas();
                    }
                });
            }
        });


        /**
         * Handle produkty dropdown on HOVER
         */
        let productsDrop = this.dropdownProducts;
        let getDropdown = document.querySelector("[aria-labelledby='produktyDropdown']");

        productsDrop.addEventListener("mouseover", e => {
            //e.preventDefault();
            productsDrop.classList.toggle('show');
            getDropdown.classList.toggle('show');
            cartDrop.classList.remove('show');
            getCartDrop.classList.remove('cart-dropdown--opened');
        });

        /**
         * Handle produkty dropdown on HOVER
         */
        let cartDrop = this.cartDropdown;
        let getCartDrop = document.querySelector("[aria-labelledby='cartDropdown']");

        cartDrop.addEventListener("mouseover", e => {
            //e.preventDefault();
            cartDrop.classList.toggle('show');
            getCartDrop.classList.toggle('cart-dropdown--opened');
            productsDrop.classList.remove('show');
            getDropdown.classList.remove('show');
        });

        /**
         * Create off canvas menu
         */
        function createOffCanvas(){
            const getCanvas = document.querySelector('.nav-responsive');

            //if offCanvas doesnt exist in <body>
            if(!document.body.contains(getCanvas)){
                const navList = document.querySelector(".navigation-items--lg");
                const clonedList = navList.cloneNode(true);
                const offCanvas = document.createElement('div');

                const closeBtn = document.createElement('div');
                closeBtn.classList.add("navigation-respo-close");
                closeBtn.innerHTML = `<a href="#" class="close-respo"><i class="bi bi-x"></i></a>`;

                const infoPanel =  document.createElement('div');
                infoPanel.classList.add("navigation-respo-contact");
                infoPanel.innerHTML = `
                                        <a href="mailto:ems@ems-elektro.cz"><i class="bi bi-envelope"></i><span>ems@ems-elektro.cz</span></a>
                                        <a href="+420 577 664 343"><i class="bi bi-telephone"></i><span>577 664 343</span></a>
                                      `;

                offCanvas.classList.add('nav-responsive');
                clonedList.appendChild(infoPanel);
                offCanvas.appendChild(clonedList);
                offCanvas.appendChild(closeBtn);
                document.body.appendChild(offCanvas);
            }
        }

        /**
         * Destroy off canvas menu
         */
        function destroyOffCanvas() {
            let getResponsiveNav = document.querySelector('.nav-responsive');
            getResponsiveNav.remove();
        }
    }
    
    //initStickyHeader(){
        // ************* //
        //   UNUSED    //
        //************//

        //use window.scrollY
        //let scrollY = window.scrollY;
        //let header = this.header;

        //window.addEventListener('scroll', function(){
            //update the value
            //scrollY = window.scrollY;
            /*
            if(scrollY > 1){
                header.classList.add('header--sticky');
            }
            else {
                header.classList.remove('header--sticky');
            }
            */
           // console.log(scrollY);
        //});
    //}

} export default ComponentNavigation;
