//import $ from "jquery";
import "babel-polyfill";
import "es6-promise/auto";
import "object-fit-images";

import "bootstrap";

import ComponentSliderProdDetail from "./components/ComponentSliderProdDetail";
import ComponentNavigation from "./components/ComponentNavigation";
import ComponentProductsFilter from "./components/ComponentProductsFilter";
import ComponentCartDropdown from "./components/ComponentCartDropdown";
import ComponentProductCounter from "./components/ComponentProductCounter";
import ComponentCartProductDelete from "./components/ComponentCartProductDelete";

//COMPONENTS that require jQuery
import "./components_jquery/ComponentCartProducts";
import {getCurrentPageIndex,moveToIndex,movePreviousPage,moveNextPage} from "./components_jquery/ComponentAjaxProducts";
import "./components_jquery/ComponentSliderLatestProducts";
//import "./components_jquery/ComponentAjaxProductsFilter";

document.addEventListener("DOMContentLoaded", () => {

    new ComponentSliderProdDetail();
    new ComponentNavigation();
    new ComponentProductsFilter();
    new ComponentCartDropdown();
    //new ComponentProductCounter();
    //new ComponentCartProductDelete();

});


/* MIXS */

$( document ).ready(function() {


    /* SEARCH BAR FOCUS CONTROL*/
    $(".search-control").focus(function() {
        $(".search").addClass("focused");
    }).focusout(function() {
        $(".search").removeClass("focused");
    });
    /* SEARCH BAR FOCUS CONTROL END s*/

    /*PAGINATION*/
    $(".page-link").on('click', function (){
        let id = $(this).attr("data-page-id");
        if(id!=='next' && id!=='previous'){
            moveToIndex(id);
        }
    });

    $(".sidebar-li").on('click', function (){
        $(".sidebar-li").removeClass("active");
        $(this).addClass("active");
    });

    /*PAGINATION END*/

    /* NAV DROPDOWN CLOSE EVENT */
    $(".top-nav-dropdown").mouseleave(function (){
        $(this).removeClass("show");
    });
    /* NAV DROPDOWN CLOSE EVENT END */

    /* cart DROPDOWN CLOSE EVENT */
    $(".cart-dropdown").mouseleave(function (){
        $('#cartDropdown').removeClass("show");
        $(this).removeClass("cart-dropdown--opened");
    });
    /* NAV DROPDOWN CLOSE EVENT END */


    /* CART NAVIGATION */
    $(".btn--steps-next").on('click', function (){
        let target = $(this).attr("aria-controls");
        $(".nav-item-custom").each(function (){
            if($(this).attr("aria-controls")==target){
                $(this).attr("aria-selected","true");
                $(this).toggleClass("active");
            }else{
                $(this).attr("aria-selected","false");
                $(this).removeClass("active");
            }
        });

        /*$(".nav-link").each(function (){
            if($(this).attr("aria-controls")==target){
                $(this).toggleClass("active");
            }else{
                $(this).removeClass("active");
            }
        });*/

        $(".tab-pane").removeClass("active").removeClass("show");
        let _target = $("body").find('#'+target);
        _target.toggleClass("active").toggleClass("show");

    });

    $(".btn--steps-back").on('click', function (){
        let target = $(this).attr("aria-controls");
        $(".nav-item-custom").each(function (){
            if($(this).attr("aria-controls")==target){
                $(this).attr("aria-selected","true");
                $(this).toggleClass("active");
            }else{
                $(this).attr("aria-selected","false");
                $(this).removeClass("active");
            }
        });

       /* $(".nav-link").each(function (){
            if($(this).attr("aria-controls")==target){
                $(this).toggleClass("active");
            }else{
                $(this).removeClass("active");
            }
        });*/


        $(".tab-pane").removeClass("active").removeClass("show");
        let _target = $("body").find('#'+target);
        _target.toggleClass("active").toggleClass("show");

    });
    /* CART NAVIGATION END */

    /* AVAILABILITY MODAL */
    $('.ask-availability').on('click', function (e) {
        e.preventDefault()
        $("#ask-availability").modal('show');
    });

    $('.close-modal').on('click', function (e) {
        e.preventDefault()
        $("#ask-availability").modal('hide');
    });
    /* AVAILABILITY MODAL END */

    /* COMPANY ORDER CONTROLLER */
    $('#orderOnCompany').on('change',function (){
        $('.order-on-company').toggleClass("active");
    });
    /* OMPANY ORDER CONTROLLER END */

    /* NEWEST PRODUCTS SLIDER */





    /*var index = 0;
    var maxSlides = (jQuery(".slide").length) - 1;

    jQuery(function() {
        jQuery( window ).on("resize", function() {
            jQuery("#slider").css("min-height", function() {
                return jQuery(".slide").outerHeight();
            });
        });
    });

    jQuery(".prev").on("click", function(){
        --index;
        if(index < 0) { index = maxSlides; }
        showSlides(index);
    });

    jQuery(".slider-next").on("click", function(){
        ++index;
        if(index > maxSlides) { index = 0; }
        showSlides(index)
    });

    function showSlides(n) {

        jQuery(".slide").eq(n).slideToggle('800', function(){
            jQuery(".slide").not(jQuery(this)).fadeOut('800');
        });

    }
    showSlides(index);
    /* NEWEST PRODUCTS SLIDER  END*/

    /* VALIDATIONS */
    function showMessage(closestFieldID, message, type){
        let colorClass = "";
        switch (type){
            case 1:
                colorClass = "color-grey";
                break;
            case 2:
                colorClass = "color-error";
                break;
            case 3:
                colorClass = "color-warning";
                break;
            default:
                colorClass = "color-grey";
                break;
        }
        if($("#"+closestFieldID).next().hasClass("info-message")){
            $("#"+closestFieldID).next().toggleClass("active").text(message).toggleClass(colorClass);
        }else{
            $("#"+closestFieldID).next().next().toggleClass("active").text(message).toggleClass(colorClass);
        }
    }

    function removeMessage(closestFieldID,colorClass){

        if($("#"+closestFieldID).next().hasClass("info-message")){
            $("#"+closestFieldID).next().removeClass("active").removeClass("color-error").removeClass("color-warning").removeClass("color-grey");
        }else{
            $("#"+closestFieldID).next().next().removeClass("active").removeClass("color-error").removeClass("color-warning").removeClass("color-grey");
        }


    }

    /*FAKE USER */
    $("#register_sub").on('click',function (){
        let pw = $("#password").val();
        let canSend = true;
        $("input").each(function (){
            if($(this).attr('required')){
                if($(this).attr('id')=="reg-email"){
                    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                    if(!regex.test($(this).val())){
                        showMessage("reg-email", "Pole není formátu emailu.",2);
                        canSend = false;
                    }else if($(this).val().length == 0){
                        showMessage("reg-email", "Pole nesmí být prázdné.",2);
                        canSend = false;
                    }else{
                        removeMessage("reg-email");
                    }
                }else if($(this).attr('id')=="agreed"){
                    if(!$(this).is('checked')){
                        showMessage("agreed", "Musíte souhlasit s podmínkami.",2);

                    }else {
                        removeMessage("agreed");
                    }
                }else{
                    if($(this).val().length == 0){
                        showMessage($(this).attr('id'), "Pole nesmí být prázdné.",2);
                        canSend = false;
                    }else{
                        removeMessage($(this).attr('id'));
                    }
                }

            }

        });

        if(canSend){
            location.href("/register-succeed.html");
        }
    });

    /* FAKE LOGIN */
    $("#login_sub").on('click',function (){
        let pw = $("#password").val();
        if(pw==="tomik"){
            showMessage("email", "Kombinace emailu a hesla nesouhlasí!",2);
        }else{
            fakeLogin();
        }

    });
    /* FAKE LOGOUT */
    $(".logout").on('click',function (){
        fakeLogout();
    });




    function fakeLogin(){
        createCookie("auth-user","Jan Novák",1);
        location.reload("/profile.html")
    }

    function fakeLogout(){
        eraseCookie("auth-user");
    }
    /*FAKE USER END*/

    /*COOKIES*/
    function createCookie(name, value, days) {
        var expires;

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    }

    function readCookie(name) {
        var nameEQ = encodeURIComponent(name) + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0)
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
        return null;
    }

    function eraseCookie(name) {
        createCookie(name, "", -1);
    }
    /*COOKIES END*/

});