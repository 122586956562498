$('.slider .newest-prods').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 4,
                rows:2

            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 4,
                rows:2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 4,
                rows:2
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
});


$('.slider .newest-prods').on('afterChange', function (event, slick, currentSlide) {
    let allSlides = slick.slideCount; //-1
    let lastSlide = Number(allSlides - 4);
    console.log(currentSlide, allSlides, lastSlide);

    if(currentSlide === lastSlide) {
        $('.newest-prods-next-arrow').addClass('hidden');
    }
    else {
        $('.newest-prods-next-arrow').removeClass('hidden');
    }

    if(currentSlide === 0) {
        $('.newest-prods-prev-arrow').addClass('hidden');
    }
    else {
        $('.newest-prods-prev-arrow').removeClass('hidden');
    }
})


$('.newest-prods-prev-arrow').click(function(){
    $('.slider .newest-prods').slick('slickPrev');
})

$('.newest-prods-next-arrow').click(function(){
    $('.slider .newest-prods').slick('slickNext');
})