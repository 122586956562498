class ComponentProductCounter {

    constructor() {
        this.productCounter = document.querySelectorAll(".product-counter");

        if(this.productCounter){
            this.initProductCounter();
        }
    }

    initProductCounter() {

        for (let i = 0; i < this.productCounter.length; i++) {
            let element = this.productCounter[i];

            let counter = element.querySelector('input[type=number]');
            let btnDown = element.querySelector('[data-product-counter="step-down"]');
            let btnUp = element.querySelector('[data-product-counter="step-up"]');

            //console.log(element);
            //console.log(counter, btnDown, btnUp);

            btnDown.addEventListener("click", e => {
                e.preventDefault();
                counter.stepDown();
            });
            btnUp.addEventListener("click", e => {
                e.preventDefault();
                counter.stepUp();
            });
        }
    }

} export default ComponentProductCounter;
