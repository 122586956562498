import { tns } from "tiny-slider";

class ComponentSliderProdDetail {

    constructor() {
        this.productSlider = document.querySelector(".product-gallery-big-wrapper");
        this.thumbsContainer = document.querySelector(".product-gallery-list-wrapper");

        if(this.productSlider){
            this.initProductSlider();
        }
    }

    initProductSlider() {

            let productsTinySlider = tns({
                container: this.productSlider,
                items: 1,
                navContainer: this.thumbsContainer,
                loop: false,
                autoHeight: false,
                controls: false,
                mouseDrag: true,
            });

            let productsThumbsSlider = tns({
                container: this.thumbsContainer,
                controls: false,
                nav: false,
                preventActionWhenRunning: true,
                autoplayTimeout: 0,
                center: false,
                loop: false,
                mouseDrag: true,
                autoHeight: false,
                responsive: {
                    320: {
                        items: 3
                    },
                    1200: {
                        items: 5
                    },
                    1440: {
                        items: 6
                    }
                }
            });

            //zmena itemu v 'thumbs' slideru pri zmene slidu v hlavnim slideru
            productsTinySlider.events.on( 'indexChanged', () => {
                productsThumbsSlider.goTo( productsTinySlider.getInfo().index - 1 );
            });
    }

} export default ComponentSliderProdDetail;
