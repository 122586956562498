import Constants from "../Constants";

class ComponentCartProductDelete {

    constructor() {
        this.delBtns = document.querySelectorAll(`[data-row-del]`);
        //this.cartDropdown = document.querySelector(".cart-dropdown");

        if(this.delBtns){
            this.initCartProductDelete();
        }
    }

    initCartProductDelete() {

        for(let i = 0; i < this.delBtns.length; i++){
            this.delBtns[i].addEventListener("click", e => {
                e.preventDefault();

                removeRow(e, this);
            });
        }

        /**
         * Delete table row
         * @param {Event} event
         * @param {Object} row
         */
        let removeRow = (event, row) => {
            let table = document.querySelector('.product-table');
            let tabRow = table.querySelector(`[data-row-del]`);
            table.deleteRow(tabRow.parentNode.parentNode.rowIndex);
        }
    }

} export default ComponentCartProductDelete;
