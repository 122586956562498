//let pageSize = 12;
global.currentPageIndex=0;
let pageCount = 0;

let startItemIndex = 0;
let itemsToDisplay = 12;

function PaginateSetPageObjects() {
    if (pageCount <= 1) {
        $('[data-page-id="next"]').addClass("disabled");
        $('[data-page-id="previous"]').addClass("disabled");
    } else {
        if (global.currentPageIndex == 0) {

            if(!$(`[data-page-id="previous"]`).hasClass("disabled")){
                $(`[data-page-id="previous"]`).addClass("disabled");
            }

            if($(`[data-page-id="next"]`).hasClass("disabled")){
                $(`[data-page-id="next"]`).removeClass("disabled");
            }

        } else if (global.currentPageIndex == Math.ceil(pageCount)) {

            if(!$(`[data-page-id="next"]`).hasClass("disabled")){
                $(`[data-page-id="next"]`).addClass("disabled");
            }

            if($(`[data-page-id="previous"]`).hasClass("disabled")){
                $(`[data-page-id="previous"]`).removeClass("disabled");
            }
        }else {
            $(`[data-page-id="previous"]`).removeClass("disabled");
            $(`[data-page-id="next"]`).removeClass("disabled");
        }
    }
}

function PaginateCalculatePageIndexes(length) {
    pageCount = 1;

    if (length > itemsToDisplay) {
        if (length / itemsToDisplay > Number(length / itemsToDisplay)) {
            pageCount = Number(length / itemsToDisplay) + 1;
        } else {
            pageCount = length / itemsToDisplay;
        }
    }

    console.log(length, itemsToDisplay, pageCount);

    /*startItemIndex = currentPageIndex * pageSize;
    let remainingItems = length - startItemIndex;
    itemsToDisplay = pageSize + startItemIndex;

    if (remainingItems < pageSize) {
        itemsToDisplay = remainingItems + startItemIndex;
    }*/
}

function displayObjects() {

    //console.log("beforeAjax", dataSort, dataProducer, dataStock);

    let dataSort = 0;
    let dataProducer = 0;
    let dataStock = 0;

    if($(".products-filter").data('sort')){
        dataSort = $(".products-filter").data('sort');
    }
    if($(".products-filter").data('producers')){
        dataProducer = $(".products-filter").data('producers');
    }
    if($("#onlyStocked").is(":checked") === true){
        dataStock = 1;
    }

    $.ajax({
        url: "./assets/data/mockup-products.json",
        type: 'GET',
        dataType: 'json',
        cors: true ,
        contentType:'application/json',
        secure: true,
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        //data: { sort: dataSort, producer: dataProducer, stock: dataStock },
        //data: 'sort='+dataSort+'&producer='+dataProducer+'&stock='+dataStock,
        success: function(data) {
           //let page = 1;

            console.log(dataSort, dataProducer, dataStock);

            let productsLimit = itemsToDisplay;
            let startRec = Math.max(global.currentPageIndex - 1, 0) * productsLimit;
            let endRec = Math.min(startRec + productsLimit, data.length);
            let recordsToShow = data.slice(startRec, endRec);

            let dataArray = [];

            $.each(data, function (index, json) {
                if(dataStock === 1){
                    if(json.onStore === true){
                        if(json.manufacturer === dataProducer && json.onStore === true){
                            //console.log(`JSON STOCKED and PRODUCER match! Produkt: [${json.id}] ${json.name} - Výrobce: ${json.manufacturer} - Skladem: ${json.onStore}`);
                            dataArray.push(json);
                            console.log(dataArray);
                        }
                        else if(json.manufacturer !== dataProducer && json.onStore === true){
                            //console.log(`JSON STOCKED match! Produkt: [${json.id}] ${json.name} - Výrobce: ${json.manufacturer} - Skladem: ${json.onStore}`);
                            dataArray.push(json);
                            console.log(dataArray);
                        }
                    }
                }
                else if(dataStock !== 1 && json.manufacturer === dataProducer){
                   //console.log(`JSON PRODUCER match! Produkt: [${json.id}] ${json.name} - Výrobce: ${json.manufacturer} - Skladem: ${json.onStore}`);
                    dataArray.push(json);
                    console.log(dataArray);
                }
            });


            if(dataArray.length === 0){
                let results = JSON.stringify(recordsToShow);
                let _data = JSON.parse(results);

                $("#products-list").empty();
                for(let i = 0; i < _data.length; i++){
                    let badge = null;
                    if(_data[i].onStore==true){
                        badge = `<label class="badges badge--stock-in">Skladem</label>`;
                    }else {
                        badge = `<label class="badges badge--stock-out">Není skladem</label>`;
                    }


                    let content = `
                        <a class="col-6 col-md-4 mb-24-md-12 product-item-wrap" href="product-detail.html#${_data[i].id}" data-price="${_data[i].price_w_taxes}" data-producer="${_data[i].manufacturer}">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <img src="./assets/images/products/${_data[i].image}">
                                </div>
                                <div class="product-item__badges">    
                               
                                ${badge}
                        
                                </div>
                                <div class="product-item__content">
                                    <p class="body-text bold color-headline title">
                                      ${_data[i].name}
                                    </p>
                                    <div class="product-item-params">
                                        <p class="body-text small">Výrobce: ${_data[i].manufacturer}</p>
                                        <p class="body-text small">Číslo zboží: ${_data[i].product_number}</p>
                                    </div>
                                </div>
                                <div class="product-item__price">
                                    <p class="body-text large bold color-headline">
                                        ${_data[i].price_w_taxes} Kč
                                    </p>
                                    <p class="body-text small-xs">
                                       ${_data[i].price_no_taxes} Kč bez DPH
                                    </p>
                                </div>
                            </div>
                        </a>           
                    `;
                    $("#products-list").append(content);
                }
            }
            else {
                let results = JSON.stringify(dataArray);
                let _data = JSON.parse(results);

                $("#products-list").empty();
                for(let i = 0; i < _data.length; i++){

                    let badge = null;
                    if(_data[i].onStore==true){
                        badge = `<label class="badges badge--stock-in">Skladem</label>`;
                    }else {
                        badge = `<label class="badges badge--stock-out">Není skladem</label>`;
                    }


                    let content = `
                        <a class="col-6 col-md-4 mb-24-md-12 product-item-wrap" href="product-detail.html#${_data[i].id}" data-price="${_data[i].price_w_taxes}" data-producer="${_data[i].manufacturer}">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <img src="./assets/images/products/${_data[i].image}">
                                </div>
                                <div class="product-item__badges">    
                               
                                   ${badge}
                        
                                </div>
                                <div class="product-item__content">
                                    <p class="body-text bold color-headline title">
                                      ${_data[i].name}
                                    </p>
                                    <div class="product-item-params">
                                        <p class="body-text small">Výrobce: ${_data[i].manufacturer}</p>
                                        <p class="body-text small">Číslo zboží: ${_data[i].product_number}</p>
                                    </div>
                                </div>
                                <div class="product-item__price">
                                    <p class="body-text large bold color-headline">
                                        ${_data[i].price_w_taxes} Kč
                                    </p>
                                    <p class="body-text small-xs">
                                       ${_data[i].price_no_taxes} Kč bez DPH
                                    </p>
                                </div>
                            </div>
                        </a>           
                    `;
                    $("#products-list").append(content);
                }
            }

            PaginateCalculatePageIndexes(data.length);
            PaginateSetPageObjects();
            CheckActiveClassOnButtons();
        },
        error: function (cc, bb, aa) { alert(aa); }
    });
}

$("[data-page-id='previous']").on('click',function (){
    event.preventDefault();
    movePreviousPage();
});

$("[data-page-id='next']").on('click',function (){
    event.preventDefault();
    moveNextPage();
});


//function moveFirstPage() { currentPageIndex = 0; displayObjects(); }
//function moveLastPage() { currentPageIndex = pageCount - 1; displayObjects(); }
function movePreviousPage() { global.currentPageIndex--; displayObjects(); }
function moveNextPage() { global.currentPageIndex++; displayObjects();}
function setPageIndex(index) { if(index!=='next' && index!=='previous') {global.currentPageIndex = index;}}
function CheckActiveClassOnButtons(){
    $(".page-link").each(function (){
        let id = $(this).attr("data-page-id");
        if(id==global.currentPageIndex){
            $(this).toggleClass("active");
        }else {
            $(this).removeClass("active");
        }
    });
}
export function moveToIndex(index) { setPageIndex(index); displayObjects();  }
export function getCurrentPageIndex() { return global.currentPageIndex; }
$(document).ready(function () { displayObjects(); });


/*
*
* FILTERING / SORTING
*
* */

$('[data-filter-trigger]').on('click', function(e, key){
    /*let dataSort = 0;
    let dataProducer = 0;
    let dataStock = 0;

    if($(".products-filter").data('sort')){
        dataSort = $(".products-filter").data('sort');
    }
    if($(".products-filter").data('producers')){
        dataProducer = $(".products-filter").data('producers');
    }
    if($("#onlyStocked").is(":checked") === true){
        dataStock = 1;
    }*/

    //displayObjects(dataSort, dataProducer, dataStock);
    displayObjects();
});
