let taxRate = 0.21; //21% DPH
//let shipping = 15.0;
const mediaQuery = window.matchMedia( "(max-width: 767px)" );

let jsonData = [
    {
        image: "assets/images/products/signalizacnikontakt.png",
        title: "1 SD signalizační kontakt",
        price: 669.48,
        priceString: "669,48",
        quantity: 1,
        total: "810,07"
    },
    {
        image: "assets/images/products/signalizacnikontakt.png",
        title: "ACTs 1ZAP+1VYP signalizace",
        price: 961.80,
        priceString: "961,80",
        quantity: 1,
        total: "1163,78"
    },
    {
        image: "assets/images/products/signalizacnikontakt.png",
        title: "1 SD signalizační kontakt",
        price: 669.48,
        priceString: "669,48",
        quantity: 1,
        total: "810,07"
    },
    {
        image: "assets/images/products/signalizacnikontakt.png",
        title: "iACTs 1ZAP+1VYP signalizace",
        price: 961.80,
        priceString: "961,80",
        quantity: 1,
        total: "1163,78"
    }
];

$(function() {
    screenClass();

    $(window).bind('resize',function(){
        screenClass();
    });

    function screenClass() {
        $(".table-wrapper").empty();

        if (mediaQuery.matches) {
            let html = `<div class="product-table--mobile table-cart--mobile mt-24">`;
            $.each(jsonData, function () {

                html +=
                    `
            <div class="cart-item cart-item--mobile cart--box-shadow">
                <div class="cart-steps-products__image--mobile">
                    <img src=" ${this.image}" alt="" />
                </div>
                <div class="cart-item--mobile__content">
                    <div class="cart-steps-products__title--mobile">
                        ${this.title}
                    </div>                        
                    <div class="cart-steps-products__price--mobile">
                        <strong class="cart-item-total">${this.total} Kč</strong>
                        <small class="cart-item-price">${this.priceString} bez DPH</small>
                    </div><div class="cart-steps-products__counter--mobile product-counter">
                        <div class="number-input" data-product-counter>
                            <button data-product-counter="step-down" class="minus">-</button>
                            <input class="quantity cart-item-qty" type="number" min="1" name="quantity" value="${this.quantity}" data-price="${this.price}">
                            <button data-product-counter="step-up" class="plus">+</button>
                        </div>
                        <a href="#" title="" data-row-del-mobile>
                            <i class="bi bi-trash"></i>
                        </a>
                    </div>
                    <div class="cart-steps-products__actions--mobile">                            
                    </div>
                </div>
            </div>
            `;
            });
            html += `</div>`;
            $(".table-wrapper").append(html);
        } else {
            let html = `<table class="product-table table-cart table-responsive">
                    <tbody class="mt-24">
                    <thead>
                        <tr>
                            <td class="product-table-product">Produkt</td>
                            <td></td>
                            <td class="product-table-counter">Množství</td>
                            <td class="product-table-price">Cena celkem</td>
                            <td></td>
                        </tr>
                    </thead>
                    `;

            $.each(jsonData, function () {

                html +=
                    `
            <tr class="cart-item">
                <td class="cart-steps-products__image">
                    <img src=" ${this.image}" alt="" />
                </td>
                <td class="cart-steps-products__title">
                    ${this.title}
                </td>
                <td class="cart-steps-products__counter product-counter">
                    <div class="number-input" data-product-counter>
                        <button data-product-counter="step-down" class="minus">-</button>
                        <input class="quantity cart-item-qty" type="number" min="1" name="quantity" value="${this.quantity}" data-price="${this.price}">
                        <button data-product-counter="step-up" class="plus">+</button>
                    </div>
                </td>
                <td class="cart-steps-products__price">
                    <strong class="cart-item-total">${this.total} Kč</strong>
                    <small class="cart-item-price">${this.priceString} bez DPH</small>
                </td>
                <td class="cart-steps-products__actions">
                    <a href="#" title="" data-row-del>
                        <i class="bi bi-trash"></i>
                    </a>
                </td>
            </tr>
            `;
            });
            html += `</tbody></table>`;
            $(".table-wrapper").append(html);
        }

        recalculateCart();
        productCounters();

        /*$(".cart-item-check").change(function() {
            recalculateCart();
        });*/

        let previousValue = $(".cart-item-qty").val();
        $(".cart-item-qty").change(function() {
            let $this = $(this);
            let parent = $this.parent().parent().parent();
            //parent.find(".cart-item-check").prop("checked", "checked");
            let price = $this.attr("data-price");
            let quantity = $this.val();
            let preTotal = price * quantity;
            let total = preTotal.toFixed(2);
            let totalComma = total.replace(".", ",");
            parent.find(".cart-item-total").html(`${totalComma} Kč`);
            recalculateCart();
        });

        $("[data-row-del]").click(function() {
            let parent = $(this)
                .parent()
                .parent();
            parent.remove();
            recalculateCart();
        });

        $("[data-row-del-mobile]").click(function() {
            let parent = $(this)
                .parent()
                .parent()
                .parent();
            parent.remove();
            recalculateCart();
        });
    }
});

function productCounters() {
    let productCounters = document.querySelectorAll(".product-counter");

    for (let i = 0; i < productCounters.length; i++) {
        let element = productCounters[i];

        let counter = element.querySelector('input[type=number]');
        let btnDown = element.querySelector('[data-product-counter="step-down"]');
        let btnUp = element.querySelector('[data-product-counter="step-up"]');

        btnDown.addEventListener("click", e => {
            e.preventDefault();
            counter.stepDown();
            counter.dispatchEvent(new Event('change'));
        });
        btnUp.addEventListener("click", e => {
            e.preventDefault();
            counter.stepUp();
            counter.dispatchEvent(new Event('change'));
        });
    }
}

function recalculateCart() {
    let subTotal = 0;
    let grandTotal = 0;
    let tax = 0;
    let items = $(".cart-item");
    $.each(items, function() {
        //let itemCheck = $(this).find(".cart-item-check");
        let itemQuantity = $(this).find(".cart-item-qty");
        //if (itemCheck.prop("checked")) {
            let itemTotal = itemQuantity.val() * itemQuantity.attr("data-price");
            subTotal += itemTotal;
        //}
    });
    if (subTotal > 0) {
        tax = subTotal * taxRate;
        grandTotal = subTotal + tax; // + shipping;
        $(".totals,.checkout").show();
    } else {
        $(".totals,.checkout").hide();
    }

    let subTotalComma = subTotal.toFixed(2).replace(".", ",");
    let grandTotalComma = grandTotal.toFixed(2).replace(".", ",");
    let taxComma = tax.toFixed(2).replace(".", ",");

    $(".cart-summary-price").html(`${subTotalComma} Kč`);
    $(".cart-summary-total").html(`${grandTotalComma} Kč`);
    $(".cart-summary-tax").html(`${taxComma} Kč`);
    //$("#cart-shipping").html(shipping.toFixed(2));
}